
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import InvitesIcon from '@/components/svg/invites-icon.svg?inline';
import {
  computed, defineComponent, onBeforeUnmount, onMounted, provide, reactive, ref, watch
} from 'vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import { getInviteCodes, updateInviteCode } from '@/services/Invitation/Api';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import { InviteCode, InviteCodesResponse } from '@/models/Invitation';
import sortComp from '@/utils/sortComp';
import { signAffiliateContract, getAffiliateContract } from '@/services/AffiliateService';
import downloadFile from '@/utils/downloadFile';
import { watchDebounced } from '@vueuse/core';
import InvitesCodesTable from '@/views/invites/InvitesCodesTable.vue';

export default defineComponent({
  name: 'InvitesCodes',
  components: {
    InvitesCodesTable,
    BCXMarkdown,
    BCXInfoSection,
    InvitesIcon,
    BCXCheckbox,
  },
  setup() {
    const { isMobileWidth } = useResponsiveness();
    const invitesResponse = reactive<InviteCodesResponse>({ inviteCodes: [] });

    const loadInvites = async () => {
      const inviteCodes = (await getInviteCodes())?.inviteCodes ?? [];
      inviteCodes.sort((a: InviteCode, b: InviteCode) => sortComp(b.userMarkedAsUsed, a.userMarkedAsUsed) * 2 + sortComp(a.userMarkedAsUsedTs, b.userMarkedAsUsedTs));
      invitesResponse.inviteCodes = inviteCodes;
    };

    const inviteCodes = computed<InviteCode[]>(() => invitesResponse.inviteCodes.filter((code) => !code.affiliate));
    const isAffiliateContractPossible = computed<boolean>(() => invitesResponse.inviteCodes.filter((code) => code.affiliate).length > 0);
    const affiliateCode = computed<string | null>(() => invitesResponse.inviteCodes.filter((code) => (code.affiliate && code.inviteCode !== '******'))?.[0]?.inviteCode);
    const affiliateCheckbox = ref(false);
    const isSigningAffiliateContract = ref(false);

    const downloadAffiliateContract = () => {
      getAffiliateContract().then((contract) => {
        downloadFile(contract.attachment, contract.filename, contract.fileType, '_blank');
      });
    };

    const signAffiliateProgram = async () => {
      isSigningAffiliateContract.value = true;
      signAffiliateContract().then(() => {
        loadInvites();
      }).finally(() => {
        affiliateCheckbox.value = false;
        isSigningAffiliateContract.value = false;
      });
    };

    onMounted(loadInvites);

    provide('invites', inviteCodes);

    const userMarked = computed<string[]>(() => inviteCodes.value.map((code) => `${code.userMarkedAsUsed} ${code.userNote}`));

    let firstPrev: string[] | null = null;
    let prev: string[] = [];

    watch(userMarked, (userMarked) => {
      if (!prev.length && userMarked?.length) prev = userMarked;
    });

    const updateInviteCodes = async (cur: string[], prev: string[]) => {
      const promises = [];
      let i;
      for (i = 0; i < cur.length; i++) {
        if (prev[i] !== cur[i]) {
          const curInviteCode = inviteCodes.value[i];
          const { userNote, inviteCode, userMarkedAsUsed } = curInviteCode;

          promises.push(
            updateInviteCode(inviteCode, {
              userNote: !userMarkedAsUsed ? '' : userNote ?? '',
              userMarkedAsUsed
            })
          );
        }
      }
      if (promises.length) {
        const results = await Promise.all(promises);
        results.forEach((resultInviteCode) => {
          const inviteCodeIndex = invitesResponse.inviteCodes.findIndex(
            (inviteCode: InviteCode) => inviteCode.inviteCode === resultInviteCode.inviteCode
          );
          if (inviteCodeIndex >= 0) {
            invitesResponse.inviteCodes.splice(inviteCodeIndex, 1, resultInviteCode);
          }
        });
      }
      firstPrev = null;
    };

    watchDebounced(userMarked, async (cur) => {
      firstPrev ??= prev;
      if (prev.length) await updateInviteCodes(cur, prev);
      prev = [...cur];
    }, {
      debounce: 1000
    });

    onBeforeUnmount(() => {
      if (firstPrev) {
        updateInviteCodes(firstPrev, userMarked.value);
      }
    });

    return {
      isMobileWidth,
      affiliateCode,
      affiliateCheckbox,
      isAffiliateContractPossible,
      isSigningAffiliateContract,
      downloadAffiliateContract,
      signAffiliateProgram
    };
  }
});
