
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { computed, defineComponent } from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXLinkedUser from '@/components/molecules/BCXLinkedUser.vue';
import useUser from '@/mixins/useUser';
import useProjectGuestState from '@/state/projectGuestState';
import MinusCircle from '@/components/svg/minus-circle.svg?inline';
import { useStore } from 'vue2-helpers/vuex';
import { CompanyGuestListResponse } from '@/models/Company';

export default defineComponent({
  name: 'ProjectGuestsTable',
  components: {
    BCXAvatar,
    BCXLinkedUser,
    MinusCircle,
  },
  setup(props, { emit }) {
    const { tUserFallback: t } = useUser('self');
    const { getFormattedDate } = useFormattedDateTime();
    const store = useStore();

    const { projectGuestList: guests, removeGuestFromProject } = useProjectGuestState();
    const project = computed(() => store.state.projectForum.project);

    const getStatus = (guest: CompanyGuestListResponse) => t(`project-forum_USERTYPE.guest-invitation.invitation-status.${guest.userRegistrationStep}`);
    const isRegistrationPending = (guest: CompanyGuestListResponse) => guest.userRegistrationStep === 'NONE';

    const convertRegisteredDate = (date: string | undefined) => {
      if (!date) return '';

      return getFormattedDate(date, false);
    };
    const removeGuest = async (guest: CompanyGuestListResponse) => {
      if (!project.value || !project.value.id) return;
      await removeGuestFromProject(guest.id, project.value.id);
    };

    return {
      t,
      guests,
      removeGuest,
      convertRegisteredDate,
      getStatus,
      isRegistrationPending,
    };
  },
});
