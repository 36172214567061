import { MaybeRef, useSessionStorage, useSorted } from '@vueuse/core';
import {
  computed, ref, unref
} from 'vue';

const useSortedArray = <T>(array: MaybeRef<T[]>,
  getFieldValueCallback: (fieldName: string, item: T) => string | number,
  defaultSortFieldName = '',
  defaultIsDescending = false,
  persistanceKey = '') => {
  const fieldName = persistanceKey ? useSessionStorage(`${persistanceKey}-field`, defaultSortFieldName) : ref(defaultSortFieldName);
  const isAscending = persistanceKey ? useSessionStorage(`${persistanceKey}-dir`, defaultIsDescending) : ref(!defaultIsDescending);

  const sortFunction = (a: T, b: T) => {
    if (!fieldName.value) return 0;
    const aValue = getFieldValueCallback(fieldName.value, a);
    const bValue = getFieldValueCallback(fieldName.value, b);

    const result = (typeof aValue === 'number' && typeof bValue === 'number') ? (aValue - bValue) : aValue.toString().localeCompare(bValue.toString());

    return isAscending.value ? result : -result;
  };

  const sortedArray = useSorted(array, sortFunction);

  return {
    fieldName, isAscending, sortedArray, array: computed(() => unref(array))
  };
};

export default useSortedArray;
