var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BCXTable,{staticClass:"bc-new-member-table",class:_setup.isMobileWidth && 'is-narrow',attrs:{"rows":_setup.members,"template-columns":"2.6fr 1fr 0.9fr 0.5fr","mobile-template-columns":"4fr 4fr 62px","mobile-row-padding":"16px 0 28px 0","mobile-grid-areas":'user user user\nstatus projects actions',"header-fields":_setup.fields,"field-names":_setup.fields,"header-titles":[
    _vm.$t('project-guests.table.user'),
    _vm.$t('project-guests.table.registered'),
    _vm.$t('project-guests.table.projects'),
    _vm.$t('project-guests.table.actions')
  ],"default-sort-field":"projects","is-sortable":"","page-size":5,"sort-value-callback":_setup.sortValueCallback,"display-value-callback":_setup.displayValueCallback,"gap":"0 16px"},scopedSlots:_vm._u([{key:"mobile-cell-header",fn:function({fieldName}){return [(fieldName==='actions' || fieldName==='user')?_c('div'):_vm._e()]}},{key:"table-cell",fn:function({fieldName, row, isMobile, i, displayValue}){return [_c('div',{key:`${fieldName}-${row.id}`,staticClass:"c-cell",class:[`c-${fieldName}`,
               {
                 'c-status-sent': fieldName==='status' && displayValue===2
               }
      ]},[(fieldName==='user')?[(isMobile)?_c(_setup.BCXUserBannerSimple,{attrs:{"size":26,"user-id":displayValue}}):_c(_setup.BCXUserBanner,{attrs:{"size":"S","show-cookies":"","show-online-indicator":"","hide-badges":"","user-id":displayValue}})]:(fieldName==='status')?[_vm._v(" "+_vm._s(_setup.getStatusString(displayValue))+" ")]:(fieldName==='projects')?[(row.status !== 1)?_c('div',{staticClass:"bcx-pointer",on:{"click":function($event){return _setup.setState(i,!_setup.openStates[i])}}},[_vm._v(" "+_vm._s(displayValue)+" "),_c(_setup.BCXChevronSwitch,{attrs:{"width":14,"height":14,"stroke-width":1.1,"value":_setup.openStates[i]}})],1):[_vm._v(" "+_vm._s(displayValue)+" ")]]:[(row.status!==1)?_c(_setup.XCircleIcon,{staticClass:"c-delete bcx-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('deactivate', row)}}}):_c(_setup.PlusIcon,{staticClass:"c-reactivate bcx-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('reactivate', row)}}}),(row.status===2)?_c(_setup.ResendIcon,{staticClass:"bcx-pointer c-resend",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('send-again', row)}}}):_vm._e()]],2)]}},{key:"table-row",fn:function({row, i}){return [_c('transition',{attrs:{"name":"bcx-transition-collapse"}},[(_setup.openStates[i])?_c('div',{key:i,staticClass:"bcx-table__full-width c-project-list"},[_c('div',[_vm._t("member-project-list",null,{"member":row})],2)]):_vm._e()])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }