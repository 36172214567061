
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import ProjectGuests from '@/views/project-forum/components/form-components/ProjectGuests.vue';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { ProjectGuest } from '@/models/Project';
import useUser from '@/mixins/useUser';
import useProjectGuestState from '@/state/projectGuestState';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'ProjectGuestInvitation',
  components: {
    BCXBreadcrumbs,
    ProjectGuests,
    BCXInfoSection
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const {
      companyId, projectList, canInviteNewGuest, inviteNewGuest
    } = useProjectGuestState();
    companyId.value = store.state.projectForum.project?.company?.id;
    projectList.value = [store.state.projectForum.project?.id];

    const breadcrumbItems = [
      {
        text: 'Projects',
        to: { name: 'projectList' }
      },
      {
        text: 'Guest Invitation'
      }
    ];

    const guestList = ref<ProjectGuest[]>([]);
    const { tUserFallback: t } = useUser('self');

    const goToProjectForum = () => {
      router.push({ name: 'projectForumThread', params: { id: route.params.id } });
    };

    const finish = async () => {
      if (canInviteNewGuest.value) {
        await inviteNewGuest();
      }
      goToProjectForum();
    };

    return {
      t,
      goToProjectForum,
      breadcrumbItems,
      guestList,
      finish
    };
  },
});
