import { RouteConfig } from 'vue-router';
import ShowTermsAndPrivacy from '@/views/registration/pages/ShowTermsAndPrivacy.vue';
import Registration from '../pages/Registration.vue';
import Verification from '../pages/Verification.vue';
import Mobilephone from '../pages/Mobilephone.vue';
import VerifyMobilephone from '../pages/VerifyMobilephone.vue';
import RegistrationFinished from '../pages/RegistrationFinished.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/register',
    name: 'register',
    component: Registration,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/register-customer',
    name: 'register-customer',
    component: Registration,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/register-guest/:inviteId'
      + '',
    name: 'register-guest',
    component: Registration,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/register/verify',
    name: 'register-verify',
    component: Verification,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/register/mobile',
    name: 'register-mobilephone',
    component: Mobilephone,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/register/mobile/verify',
    name: 'register-mobilephone-verify',
    component: VerifyMobilephone,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/register/finished',
    name: 'register-finished',
    component: RegistrationFinished,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/@show-terms',
    name: 'show-terms',
    component: ShowTermsAndPrivacy,
    meta: {
      layout: 'plain'
    }
  },
  {
    path: '/@show-privacy',
    name: 'show-privacy',
    component: ShowTermsAndPrivacy,
    meta: {
      layout: 'plain'
    }
  }
];

export default routes;
