
import {
  computed, defineComponent, ref, PropType
} from 'vue';
import AddIcon from '@/views/profile/components/AddIcon.vue';
import IconAdd from '@/components/svg/plus-circle.svg?inline';
import useUser from '@/mixins/useUser';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useTriggerLegalTextsPopup from '@/mixins/useTriggerLegalTextsPopup';
import useProjectGuestState, { NewProjectGuest } from '@/state/projectGuestState';

export default defineComponent({
  name: 'ProjectGuestsInviteNew',
  components: {
    BCXCheckbox,
    BCXMarkdown,
    AddIcon,
    IconAdd
  },
  props: {
    mode: {
      type: String as PropType<'create' | 'edit'>,
      default: 'create',
    },
    value: {
      type: Object as PropType<NewProjectGuest>,
      default: () => ({
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        accepted: false,
      }),
    },
  },
  emits: ['add-new-guest'],
  setup(props, { emit }) {
    const { tUserFallback: t } = useUser('self');
    const { setLegalText } = useTriggerLegalTextsPopup();
    const showTerms = () => setLegalText('terms');
    const showPrivacy = () => setLegalText('privacy');
    const showForm = ref(false);
    const showInlineAdd = computed(() => props.mode === 'edit');
    const toggleForm = () => {
      showForm.value = !showForm.value;
    };

    const { newProjectGuest: newGuest, canInviteNewGuest: canAdd, inviteNewGuest } = useProjectGuestState();

    const addNewGuest = () => {
      inviteNewGuest();
    };

    return {
      t,
      showForm,
      toggleForm,
      newGuest,
      canAdd,
      addNewGuest,
      showTerms,
      showPrivacy,
      showInlineAdd,
    };
  },
});
