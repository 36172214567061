
import {
  computed, defineComponent, PropType, toRef
} from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import { User } from '@/models/User';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'BCXUserListItem',
  components: {
    BCXAvatar
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
  },
  setup(props) {
    const userProp = toRef(props, 'user');
    const user = useUser(userProp);

    const userId = computed(() => user.userId);
    const displayName = computed(() => user.displayName.value);
    return {
      userId,
      displayName
    };
  }
});
