
import SidebarContent from '@/components/SidebarContent.vue';
import { InfoBoxType } from '@/models/InfoBox';
import RecommendUserForProjects from '@/views/recommendations/components/recommend-user-for-projects/RecommendUserForProjects.vue';
import ProfileSidebarTitle from '@/views/profile/components/sidebar/ProfileSidebarTitle.vue';
import useRecommendations from '@/views/profile/composables/useRecommendations';
import {
  defineComponent, ref
} from 'vue';
import useUser from '@/mixins/useUser';
import { not } from '@vueuse/math';

export default defineComponent({
  name: 'ProfileSidebar',
  components: {
    ProfileSidebarTitle,
    SidebarContent,
    RecommendUserForProjects
  },
  props: {
    inBox: {
      type: Boolean
    }
  },
  setup() {
    const isOpened = ref(true);
    const { availability, sidebarType, user } = useRecommendations();
    const { isCustomer, displayName: userName } = useUser(user);
    const { isGuest: isCurrentUserGuest } = useUser('self');

    const isVisible = not(isCustomer) && not(isCurrentUserGuest);

    return {
      isOpened,
      user,
      availability,
      InfoBoxType,
      sidebarType,
      isVisible,
      userName,
      isCurrentUserGuest,
    };
  }
});
